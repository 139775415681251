var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.htmlTag ? _vm.htmlTag : 'div',{tag:"component",class:['o-cover-slider', 'relative w-full']},[(_vm.video && _vm.video !== '')?_c('BowVideo',{staticClass:"absolute h-screen left-0 top-0 w-full z-0",attrs:{"autoplay":true,"provider":_vm.video.provider,"src":_vm.video.url}}):(!_vm.video && _vm.media[0] && _vm.media[0].type === 'image')?_c('BowImage',{attrs:{"classPicture":"absolute block h-screen left-0 top-0 w-full z-0","classImage":"h-full object-cover relative w-full","image":_vm.media[0].image,"radius":false}}):_vm._e(),_vm._v(" "),_c('BowOverlay'),_vm._v(" "),_c('div',{class:[
            'pb-12 pt-[60vh] relative w-full z-[2]',
            'lg:grid lg:grid-cols-5 lg:items-center lg:min-h-screen lg:pt-0 lg:pb-0' ]},[_c('div',{staticClass:"px-6 text-center w-full lg:col-span-2 lg:pl-16 lg:pr-4 lg:text-left lg:text-white lg:z-10 xl:pl-[136px] 2xl:pr-10"},[(_vm.title && _vm.title.length === 1)?_c('BowHeading',{staticClass:"text-white",attrs:{"align":"text-center lg:text-left","size":"7xl","weight":"font-bold"}},[_vm._v("\n                "+_vm._s(_vm.title[0].value_heading)+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.checkBreakpoint.down.lg)?_c('div',{ref:"bar",class:[
                    'bg-white/20 h-[30px] mt-4 mx-auto relative w-0.5' ]},[_c('span',{class:[
                        'absolute block border-r-2 border-r-secondary duration-[10s] left-0',
                        'h-full origin-top scale-y-0 top-0 transition-all w-full' ]})]):_vm._e(),_vm._v(" "),(_vm.checkBreakpoint.up.lg && _vm.cta && _vm.cta.length > 0)?_c('BowCallToAction',{attrs:{"cta":_vm.cta,"className":"hidden mt-12 relative lg:inline-flex","componentType":"circle","theme":"secondary"}}):_vm._e()],1),_vm._v(" "),_c('BowSlider',{staticClass:"mt-4 lg:mt-0 lg:col-span-3 lg:max-h-[400px]",attrs:{"breakpoints":{
                640: {
                    slidesPerView: 2.5,
                },
                768: {
                    slidesPerView: 2.75,
                },
                1024: {
                    centeredSlides: false,
                    slidesPerView: 2.5,
                    spaceBetween: 24,
                },
                1536: {
                    centeredSlides: false,
                    slidesPerView: 3,
                    spaceBetween: 32,
                },
                1700: {
                    centeredSlides: false,
                    slidesPerView: 3.5,
                    spaceBetween: 32,
                },
            },"centeredSlides":true,"fadeOutSlide":true,"loop":true,"navigation":false,"navigationThumb":false,"pagination":false,"paginationThumb":false,"spaceBetween":16,"thumb":false}},_vm._l((_vm.slides),function(v,i){return _c('BowSlide',{key:i},[_c('BowCard',{attrs:{"image":v.image,"link":v.link,"title":[_vm.$t(v.pretitle), v.title],"subtitle":v.subtitle}})],1)}),1),_vm._v(" "),(_vm.checkBreakpoint.down.lg && _vm.cta && _vm.cta.length > 0)?_c('BowCallToAction',{staticClass:"flex items-center justify-center mt-8 px-6 w-full lg:hidden",attrs:{"componentType":"default","cta":_vm.cta,"hollow":true,"theme":"primary"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }