var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"element",tag:"component",staticClass:"m-card group relative",on:{"mouseenter":function($event){return _vm.$emit('mouseenter')},"mouseleave":function($event){return _vm.$emit('mouseleave')}}},[_c('BowRing',{class:[
            'scale-x-card-x scale-y-card-y transition-transform duration-500 z-10',
            'xl:group-hover:scale-100' ]}),_vm._v(" "),_c('div',{class:[
            'overflow-hidden relative rounded-[50vw]',
            'transition-transform duration-500 xl:group-hover:scale-x-card-x xl:group-hover:scale-y-card-y' ]},[_c(_vm.link && _vm.link !== '' ? 'NuxtLink' : 'div',{tag:"component",staticClass:"block pt-media-padding relative w-full z-0",attrs:{"title":_vm.link && _vm.link !== '' && _vm.title
                    ? ((_vm.title[0]) + " " + (_vm.title[1]))
                    : null,"to":_vm.link && _vm.link !== '' ? _vm.localePath(_vm.link) : null}},[_c('BowImage',{staticClass:"z-0",attrs:{"classPicture":"h-full w-full","classImage":[
                    'absolute h-full left-0 object-cover top-0 w-full' ],"image":_vm.image,"size":"bow-thumb-card","sizes":""}}),_vm._v(" "),_c('div',{class:[
                    'bg-gradient-to-b from-black/0 to-black/30',
                    'bg-no-repeat bg-clip-padding',
                    'absolute top-0 left-0 h-full w-full z-1' ]})],1)],1),_vm._v(" "),_c('div',{class:[
            'absolute flex flex-col h-full items-center justify-end',
            'left-0 pointer-events-none px-4 py-[74px] text-white top-0',
            'w-full z-[2]' ]},[_c('BowHeading',{attrs:{"align":"text-center","weight":"font-bold"}},[_c('span',{staticClass:"block leading-9 text-lg 2xl:text-2xl 2xl:leading-9"},[_vm._v(_vm._s(_vm.title[0]))]),_vm._v(" "),_c('span',{staticClass:"block leading-9 text-2xl 2xl:text-4xl 2xl:leading-9"},[_vm._v(_vm._s(_vm.title[1]))])]),_vm._v(" "),_c('p',{staticClass:"mt-2 text-lg"},[_vm._v("\n            "+_vm._s(_vm.subtitle)+"\n        ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }