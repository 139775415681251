//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from "vuex";
import { checkBreakpoint, defaultBlockProps } from "~/helpers";

export default {
    name: "BowCoverSlider",

    props: {
        ...defaultBlockProps,
    },

    data: () => ({
        checkBreakpoint: {
            up: {
                lg: false,
            },
            down: {
                lg: false,
            },
        },
        slides: [],
        video: "",
    }),

    async fetch() {
        const immobiliCount =
            this.$store.getters["immobili/GET_IMMOBILI_COUNT"];

        if (immobiliCount === 0) {
            await this.$store.dispatch("immobili/UPDATE_IMMOBILI", null);
        }

        if (this.immobili.length > 0) {
            this.immobili.forEach((it) => {
                if (it.isFeatured === true) {
                    const { card } = it;

                    this.slides.push({
                        pretitle: card.pretitle ? card.pretitle : null,
                        title: card.title ? card.title : null,
                        subtitle: card.subtitle ? card.subtitle : card.subtitle,
                        image: card.image ? card.image : null,
                        link: card.link,
                    });
                }
            });
        }

        if (this.media && this.media.length > 0) {
            if (this.media[0].type === "video") {
                this.video = {
                    url: this.media[0].url,
                    provider: "wordpress",
                };
            }
        }
    },

    computed: {
        ...mapState({
            immobili: ({ immobili }) => immobili.immobili,
            immobiliCount: ({ immobili }) => immobili.immobiliCount,
        }),

        ...mapGetters(["immobili/GET_IMMOBILI", "immobili/GET_IMMOBILI_COUNT"]),
    },

    mounted() {
        this.checkBreakpoint.up.lg = checkBreakpoint.up("lg");
        this.checkBreakpoint.down.lg = checkBreakpoint.down("lg");

        this.$nuxt.$emit("update-locomotive");

        if (this.checkBreakpoint.down.lg && this.$refs.bar) {
            this.$anime({
                targets: this.$refs.bar.querySelector("span"),
                autoplay: true,
                duration: 10000,
                delay: 3000,
                scaleY: 1,
            });
        }
    },

    methods: {
        ...mapActions(["immobili/UPDATE_IMMOBILI"]),
    },
};
