import { render, staticRenderFns } from "./BowCoverSlider.vue?vue&type=template&id=dcf6c386&"
import script from "./BowCoverSlider.vue?vue&type=script&lang=js&"
export * from "./BowCoverSlider.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BowVideo: require('/workspace/components/atoms/BowVideo/BowVideo.vue').default,BowImage: require('/workspace/components/atoms/BowImage/BowImage.vue').default,BowOverlay: require('/workspace/components/atoms/BowOverlay/BowOverlay.vue').default,BowHeading: require('/workspace/components/atoms/BowHeading/BowHeading.vue').default,BowCallToAction: require('/workspace/components/molecules/BowCallToAction/BowCallToAction.vue').default,BowCard: require('/workspace/components/molecules/BowCard/BowCard.vue').default,BowSlide: require('/workspace/components/molecules/BowSlider/BowSlide.vue').default,BowSlider: require('/workspace/components/molecules/BowSlider/BowSlider.vue').default})
