//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "BowVideo",
    props: {
        autoplay: {
            default: false,
            type: Boolean,
        },
        htmlTag: {
            default: null,
            type: String,
        },
        provider: {
            default: "wordpress",
            type: String,
        },
        src: {
            default: null,
            type: String,
        },
    },
    data: () => ({}),
    mounted() {
        const interval = setInterval(() => {
            const video = this.$el.querySelector("video");

            const countForVideo = video.readyState;

            if (countForVideo === 4) {
                const playVideo = video.play();

                playVideo
                    .then((_) => {})
                    .catch((error) => {
                        console.error(error);
                    });

                clearInterval(interval);
            }
        }, 1000);
    },
};
