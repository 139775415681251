//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "BowCard",
    props: {
        image: {
            // default: () => ({
            //   height: 1080,
            //   id: 'photo-1601235116901-2dc694b4cb1b',
            //   ixid: 'MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8',
            //   provider: 'unsplash',
            //   width: 1920
            // }),
            default: null,
            type: Object,
        },
        link: {
            default: null,
            type: String,
        },
        title: {
            default: () => [],
            type: Array,
        },
        subtitle: {
            default: "",
            type: String,
        },
    },
    data: () => ({}),
};
